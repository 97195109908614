<template>
    <v-container grid-list-xl fluid>
        <Notifier />
        <Charger />
    </v-container>
</template>

<script>
import Charger from "./Charger";
import Notifier from "./Notifier";

export default {
    components: { Charger, Notifier },
}
</script>
